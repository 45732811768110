<template>
  <!--begin::Stats Widget 22-->
  <div
    class="card card-custom bgi-no-repeat card-stretch gutter-b"
    style="
      background-position: right top;
      background-size: 40% auto;
      background-image: url(/media/svg/shapes/snailcoin-text.svg);
    "
  >
    <!--begin::Body-->
    <div class="card-body">
      <a href="#" class="card-title font-weight-bolder text-primary font-size-h1 text-hover-state-dark">
        Saldo SnailCoin
      </a>
      <div class="font-weight-bold text-muted font-size-sm mt-2">
        <span class="text-dark-75 font-weight-bolder font-size-h1 display-4 mr-2">
          {{ currentWalletSnail.balance | currency('', 2, { thousandsSeparator: '.', decimalSeparator: ',' }) }}
        </span>
        SN$
      </div>
      <div v-if="currentWalletSnail.limit">
        <div class="progress progress-xl mt-5 bg-primary-o-50">
          <div
            class="progress-bar bg-primary"
            role="progressbar"
            :style="'width:' + (currentWalletSnail.limit.value_in_use * 100) / currentWalletSnail.limit.value + '%'"
            :aria-valuenow="(currentWalletSnail.limit.value_in_use * 100) / currentWalletSnail.limit.value"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <div class="d-flex align-items-center justify-content-between mt-3">
          <div class="text-muted font-weight-bold mr-2 d-flex flex-column">
            Limite utilizado
            <span class="text-info font-size-sm mb-2">
              {{
                currentWalletSnail.limit.value_in_use
                  | currency('SN$ ', 2, { thousandsSeparator: '.', decimalSeparator: ',' })
              }}
            </span>
            <b-button
              variant="primary"
              class="btn btn-xs btn-light"
              :disabled="currentWalletSnail.limit.value_in_use <= 0"
              @click="recompose()"
            >
              Recompor
            </b-button>
          </div>
          <div class="text-muted font-weight-bold mr-2 d-flex flex-column">
            Limite disponível
            <span class="text-success font-size-sm text-right mb-2">
              {{
                currentWalletSnail.limit.value_available
                  | currency('SN$ ', 2, { thousandsSeparator: '.', decimalSeparator: ',' })
              }}
            </span>
            <b-button
              variant="primary"
              class="btn btn-xs btn-primary"
              :disabled="currentWalletSnail.limit.value_available <= 0"
              @click="emit()"
            >
              Emitir
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <!--end::Body-->
  </div>
  <!--end::Stats Widget 22-->
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['currentWalletSnail'])
  },

  methods: {
    async emit() {
      const inputValue = 0;
      const inputStep = 1000;

      this.$swal.fire({
        input: 'range',
        inputValue,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Emitir',
        html:
          '<div class="mb-5"><b>Você possuí um valor de' +
          `<h1 class="text-primary">${this.$options.filters.currency(
            this.currentWalletSnail.limit.value_available,
            'SN$'
          )}</h1>` +
          'disponíveis para emissão.</b></div>' +
          'Insira abaixo o valor que deseja emitir' +
          `<div class="input-group mt-5">
					  <div class="input-group-prepend">
								<span class="input-group-text">SN$</span>
						</div>
								<input type="number" class="form-control" placeholder="0.00" value="${inputValue}" step="${inputStep}" max="${this.currentWalletSnail.limit.value_available}"  class="swal2-input" id="range-value">	
					</div>
        `,
        inputAttributes: {
          min: 0,
          max: this.currentWalletSnail.limit.value_available,
          step: inputStep
        },
        showLoaderOnConfirm: true,
        didOpen: () => {
          const inputRange = this.$swal.getInput();
          const inputNumber = this.$swal.getHtmlContainer().querySelector('#range-value');
          // remove default output
          inputRange.nextElementSibling.style.display = 'none';
          inputRange.style.width = '100%';
          // sync input[type=number] with input[type=range]
          inputRange.addEventListener('input', () => {
            inputNumber.value = inputRange.value;
          });

          // sync input[type=range] with input[type=number]
          inputNumber.addEventListener('change', () => {
            inputRange.value = inputNumber.value;
          });
        },
        inputValidator: (value) => {
          if (parseInt(value) == 0) {
            return 'Insira um valor';
          }
          if (parseInt(value) > this.currentWalletSnail.limit.value_available) {
            return 'Valor solicitado superior ao limite disponível';
          }
        },
        preConfirm: async (value) => {
          await this.$store
            .dispatch('STORE_EMIT_SNAIL', value)
            .then((response) => {
              this.$swal({
                title: response.success,
                icon: 'success'
              });
            })
            .catch(() => {
              this.$swal.showValidationMessage(`Ocorreu um problema.`);
            });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    async recompose() {
      const inputValue = 0;
      const inputStep = 1000;

      this.$swal.fire({
        input: 'range',
        inputValue,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Recompor',
        html:
          '<div class="mb-5"><b>Você possuí um valor de' +
          `<h1 class="text-primary">${this.$options.filters.currency(
            this.currentWalletSnail.limit.value_in_use,
            'SN$'
          )}</h1>` +
          'emitidos.</b></div>' +
          'Insira abaixo o valor que deseja recompor' +
          `<div class="input-group mt-5">
					  <div class="input-group-prepend">
								<span class="input-group-text">SN$</span>
						</div>
								<input type="number" class="form-control" placeholder="0.00" value="${inputValue}" step="${inputStep}" max="${
            this.currentWalletSnail.limit.value_available
          }"  class="swal2-input" id="range-value">	
					</div>
            <div class='mt-1 text-success'>Saldo disponível: <b>${this.$options.filters.currency(
              this.currentWalletSnail.balance,
              'SN$'
            )}</b></div>
        
        `,
        inputAttributes: {
          min: 0,
          max: this.currentWalletSnail.limit.value_in_use,
          step: inputStep
        },
        showLoaderOnConfirm: true,
        didOpen: () => {
          const inputRange = this.$swal.getInput();
          const inputNumber = this.$swal.getHtmlContainer().querySelector('#range-value');
          // remove default output
          inputRange.nextElementSibling.style.display = 'none';
          inputRange.style.width = '100%';

          // sync input[type=number] with input[type=range]
          inputRange.addEventListener('input', () => {
            inputNumber.value = inputRange.value;
          });

          // sync input[type=range] with input[type=number]
          inputNumber.addEventListener('change', () => {
            inputRange.value = inputNumber.value;
          });
        },
        inputValidator: (value) => {
          if (parseInt(value) == 0) {
            return 'Insira um valor';
          }
          if (parseInt(value) > this.currentWalletSnail.limit.value_in_use) {
            return 'Valor solicitado superior ao limite disponível';
          }
        },
        preConfirm: async (value) => {
          await this.$store
            .dispatch('STORE_RECOMPOSE_SNAIL', value)
            .then((response) => {
              this.$swal({
                title: response.success,
                icon: 'success'
              });
            })
            .catch(() => {
              this.$swal.showValidationMessage(`Ocorreu um problema.`);
            });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    }
  }
};
</script>
