<template>
  <div
    class="card card-custom bgi-no-repeat card-stretch gutter-b"
    style="
      background-position: right top;
      background-size: 40% auto;

      background-image: url(/media/svg/shapes/real-text.svg);
    "
  >
    <!--begin::Body-->
    <div class="card-body">
      <a href="#" class="card-title font-weight-bolder text-success font-size-h1 text-hover-state-dark">Saldo Real</a>
      <div class="font-weight-bold text-muted font-size-sm mt-2">
        <span class="text-dark-75 font-weight-bolder font-size-h1 mr-2">
          {{ currentWalletReal.balance | currency('', 2, { thousandsSeparator: '.', decimalSeparator: ',' }) }}
        </span>
        R$
      </div>

      <div class="d-flex align-items-center justify-content-between mt-5">
        <a
          class="btn btn-success font-weight-bold btn-lg btn-block"
          :class="{ disabled: !currentWalletReal }"
          @click="deposit()"
        >
          <span class="svg-icon svg-icon-md svg-icon-white mr-2">
            <inline-svg src="/media/svg/icons/Text/Redo.svg" />
          </span>
          Depositar
        </a>
      </div>
      <div class="d-flex align-items-center justify-content-between mt-5">
        <a
          class="btn btn-success font-weight-bold btn-lg btn-block"
          :class="{ disabled: !currentWalletReal }"
          @click="deposit()"
        >
          <span class="svg-icon svg-icon-md svg-icon-white mr-2">
            <inline-svg src="/media/svg/icons/Text/Undo.svg" />
          </span>
          Sacar
        </a>
      </div>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import OverlayLoader from '@/shared/content/OverlayLoader.vue';

export default {
  computed: {
    ...mapGetters(['currentWalletReal'])
  },
  methods: {
    async deposit() {
      await this.$router.push({ name: 'wallet-real-deposit' });
      this.$bvModal.show('modal-new-deposit');
    }
  }
};
</script>
