<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
      <div class="col-lg-4">
        <CardTwoWallets :card-stretch="true"></CardTwoWallets>
      </div>
      <div class="col-lg-8">
        <div class="row">
          <div class="col-lg-6"><CardWalletSnail /></div>
          <div class="col-lg-6"><CardWalletReal /></div>
        </div>
        <OthersWidget></OthersWidget>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <LatestAccess></LatestAccess>
      </div>
      <div class="col-lg-8">
        <LatestTransactions></LatestTransactions>
      </div>
    </div>

    <!--end::Dashboard-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CardTwoWallets from '@/shared/components/wallet/CardTwoWallets';
import OthersWidget from './components/OthersWidget';
import LatestTransactions from './components/LatestTransactions';
import LatestAccess from './components/LatestAccess';
import CardWalletSnail from '@/shared/components/wallet/CardWalletSnail';
import CardWalletReal from '@/shared/components/wallet/CardWalletReal';

export default {
  name: 'Dashboard',
  components: {
    CardTwoWallets,
    OthersWidget,
    LatestTransactions,
    LatestAccess,
    CardWalletSnail,
    CardWalletReal
  },
  data() {
    return {
      activeItem: 'dashboard_snail'
    };
  },
  computed: mapGetters(['currentStatus']),
  mounted() {
    this.$store.dispatch('SET_BREADCRUMB', [{ title: 'Dashboard' }]);
  },
  methods: {
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
    },

    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll('.nav-link');
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove('active');
      }

      // set current active tab
      event.target.classList.add('active');

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute('data-tab'));
    }
  }
};
</script>
