<template>
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">Últimas Transações</span>
        <span class="text-muted mt-2 font-weight-bold font-size-sm">
          Veja aqui suas últimas transções com sua carteira.
        </span>
      </h3>
      <div class="card-toolbar">
        <ul class="nav nav-pills nav-pills-sm nav-dark-75">
          <li class="nav-item">
            <a
              class="nav-link py-2 px-4"
              :class="{ active: isActive('extract_snail') }"
              href="#extract_snail"
              @click.prevent="setActive('extract_snail')"
            >
              Carteira Snail
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link py-2 px-4"
              :class="{ 'active bg-success': isActive('extract_real') }"
              href="#extract_real"
              @click.prevent="setActive('extract_real')"
            >
              Carteira Reais
            </a>
          </li>
        </ul>
      </div>
    </div>

    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <div id="myTabContent" class="tab-content mt-5">
        <!--begin::Tap pane-->
        <div class="tab-pane" :class="{ 'active show': isActive('extract_snail') }">
          <!--begin::Table-->
          <TableExtractSnail :limit="5" :is-pagination="false" :show-footer="false" />
          <!--end::Table-->
        </div>
        <!--end::Tap pane-->
        <!--begin::Tap pane-->
        <div class="tab-pane" :class="{ 'active show': isActive('extract_real') }">
          <!--begin::Table-->
          <TableExtractReal :limit="5" :is-pagination="false" :show-footer="false" />
          <!--end::Table-->
        </div>
        <!--end::Tap pane-->
      </div>
    </div>
    <!--end::Body-->
    <!--end::Advance Table Widget 2-->
  </div>
</template>

<script>
import TableExtractSnail from '@/shared/components/extract/TableExtractSnail';
import TableExtractReal from '@/shared/components/extract/TableExtractReal';

export default {
  name: 'LatestTransactions',
  components: {
    TableExtractSnail,
    TableExtractReal
  },
  data() {
    return {
      activeItem: 'extract_snail'
    };
  },
  methods: {
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
    }
  }
};
</script>
