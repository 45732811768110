<template>
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header">
      <h3 class="card-title font-weight-bolder text-dark">Últimos acessos</h3>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <template v-for="(item, i) in list">
        <div :key="i" class="mb-6">
          <!--begin::Content-->
          <div class="d-flex align-items-center flex-grow-1">
            <!--begin::Section-->
            <div class="d-flex flex-wrap align-items-center justify-content-between w-100">
              <!--begin::Info-->
              <div class="d-flex flex-column align-items-cente py-2 w-75">
                <!--begin::Title-->
                <a href="#" class="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1">
                  {{ item.title }}
                </a>
                <!--end::Title-->
                <!--begin::Data-->
                <span class="text-muted font-weight-bold">{{ item.desc }}</span>
                <!--end::Data-->
              </div>
              <!--end::Info-->
              <!--begin::Label-->
              <span class="label label-lg label-inline font-weight-bold py-4" :class="`label-light-${item.class}`">
                {{ item.status }}
              </span>
              <!--end::Label-->
            </div>
            <!--end::Section-->
          </div>
          <!--end::Content-->
        </div>
      </template>
      <!--begin::Item-->
      <!--end::Item-->
    </div>
    <!--end: Card Body-->
  </div>
</template>

<script>
export default {
  name: 'Widget13',
  components: {},
  data() {
    return {
      list: [
        {
          title: '201.1.207.155',
          desc: 'BR Brazil Brasil Sao Paulo SP Taboao da Serra',
          status: 'agora',
          class: 'success'
        },
        {
          title: '201.1.207.155',
          desc: 'BR Brazil Brasil Sao Paulo SP Taboao da Serra',
          status: 'há 9 horas',
          class: 'primary'
        },
        {
          title: '201.1.207.155',
          desc: 'BR Brazil Brasil Sao Paulo SP Taboao da Serra',
          status: 'há 4 horas',
          class: 'primary'
        },
        {
          title: '201.1.207.155',
          desc: 'BR Brazil Brasil Sao Paulo SP Taboao da Serra',
          status: 'há 5 horas',
          class: 'primary'
        }
      ]
    };
  }
};
</script>
