<template>
  <!--begin::Tiles Widget 8-->

  <OverlayLoader
    :is-submitting="!walletSnail.address"
    variant="dark"
    message="Sua Wallet é criada automaticamente após a validação dos seus documentos."
    :timing="true"
  >
    <div class="card card-custom gutter-b" :class="{ 'card-stretch': cardStretch }">
      <!-- card-stretch -->
      <!--begin::Header-->
      <div class="card-header d-flex justify-content-center">
        <div class="card-toolbar">
          <ul class="nav nav-lg nav-light-dark nav-bold nav-pills justify-content-center p-5">
            <li class="nav-item">
              <a
                class="nav-link"
                :class="{ active: isActive('wallet_snail') }"
                href="#wallet_snail"
                data-toggle="tab"
                @click.prevent="setActive('wallet_snail')"
              >
                <span class="nav-icon svg-icon svg-icon-primary">
                  <inline-svg src="/media/svg/icons/Shopping/snail-money.svg" />
                </span>
                <span class="nav-text text-primary">Carteira Snail</span>
              </a>
            </li>

            <li class="nav-item">
              <a
                class="nav-link"
                :class="{ active: isActive('wallet_reais') }"
                href="#wallet_reais"
                data-toggle="tab"
                @click.prevent="setActive('wallet_reais')"
              >
                <span class="nav-icon svg-icon svg-icon-success">
                  <inline-svg src="/media/svg/icons/Shopping/Money.svg" />
                </span>
                <span class="nav-text text-success">Carteira Reais</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body d-flex flex-column p-0">
        <div id="myTabContent" class="tab-content">
          <div class="tab-pane animate__animated animate__flipInY" :class="{ 'active show': isActive('wallet_snail') }">
            <!--begin::Items-->
            <div class="flex-grow-1 card-spacer">
              <div class="text-center mb-10">
                <qrcode-vue
                  class="qrCode"
                  :value="walletSnail.address"
                  level="H"
                  render-as="svg"
                  foreground="#1775c6"
                ></qrcode-vue>
              </div>
              <!--begin::Item-->
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center mr-2">
                  <div>
                    <span class="font-size-h6 text-dark-75 font-weight-bolder">Data criação</span>
                    <div class="font-size-sm text-muted font-weight-bold mt-1">Data da abertura da sua carteira</div>
                  </div>
                </div>
                <div class="label label-primary label-inline font-weight-bold py-4 px-3 font-size-base">
                  {{ walletSnail.created_at }}
                </div>
              </div>
              <!--end::Item-->

              <!--begin::Item-->
              <div class="d-flex align-items-center justify-content-between mt-5">
                <div class="d-flex align-items-center mr-2">
                  <div>
                    <span class="font-size-h6 text-dark-75 font-weight-bolder">Última Transação</span>
                    <div class="font-size-sm text-muted font-weight-bold mt-1">
                      Data da última transação na carteira
                    </div>
                  </div>
                </div>
                <div class="label label-primary label-inline font-weight-bold py-4 px-3 font-size-base">
                  {{ walletSnail.created_at }}
                </div>
              </div>
              <!--end::Item-->
            </div>

            <!--end::Items-->
            <div class="card-footer d-flex justify-content-center bg-transparent align-items-center flex-wrap">
              <router-link v-slot="{ href, navigate }" custom to="/financial/snail">
                <a
                  :href="href"
                  class="btn btn-icon btn-primary btn-lg btn-shadow m-2 py-12 px-14 d-flex flex-column"
                  @click="navigate"
                >
                  <span class="svg-icon svg-icon-2x mb-2">
                    <inline-svg src="/media/svg/icons/Layout/Layout-left-panel-2.svg" />
                  </span>
                  <span class="nav-text font-weight-bolder">Extrato</span>
                </a>
              </router-link>

              <router-link v-slot="{ href, navigate }" custom to="/financial/snail/transfer">
                <a
                  :href="href"
                  class="btn btn-icon btn-primary btn-lg btn-shadow m-2 py-12 px-14 d-flex flex-column"
                  @click="navigate"
                >
                  <span class="svg-icon svg-icon-2x mb-2">
                    <inline-svg src="/media/svg/icons/Navigation/Up-down.svg" />
                  </span>
                  <span class="nav-text font-weight-bolder">Transferir</span>
                </a>
              </router-link>

              <router-link v-slot="{ href, navigate }" custom to="/financial/snail/transfer">
                <a
                  :href="href"
                  class="btn btn-icon btn-primary btn-lg btn-shadow m-2 py-12 px-14 d-flex flex-column"
                  @click="navigate"
                >
                  <span class="svg-icon svg-icon-2x mb-2">
                    <inline-svg src="/media/svg/icons/Shopping/ATM.svg" />
                  </span>
                  <span class="nav-text font-weight-bolder">Pagar</span>
                </a>
              </router-link>
            </div>
          </div>

          <div class="tab-pane animate__animated animate__flipInY" :class="{ 'active show': isActive('wallet_reais') }">
            <!--begin::Items-->
            <div class="flex-grow-1 card-spacer">
              <div class="text-center mb-10">
                <qrcode-vue
                  class="qrCode"
                  :value="walletReais.address"
                  level="H"
                  render-as="svg"
                  foreground="#2e9e01"
                ></qrcode-vue>
              </div>
              <!--begin::Item-->
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center mr-2">
                  <div>
                    <span class="font-size-h6 text-dark-75 font-weight-bolder">Data criação</span>
                    <div class="font-size-sm text-muted font-weight-bold mt-1">Data da abertura da sua carteira</div>
                  </div>
                </div>
                <div class="label label-success label-inline font-weight-bold py-4 px-3 font-size-base">
                  {{ walletReais.created_at }}
                </div>
              </div>
              <!--end::Item-->

              <!--begin::Item-->
              <div class="d-flex align-items-center justify-content-between mt-5">
                <div class="d-flex align-items-center mr-2">
                  <div>
                    <span class="font-size-h6 text-dark-75 font-weight-bolder">Última trasação</span>
                    <div class="font-size-sm text-muted font-weight-bold mt-1">
                      Data da última transação na carteira
                    </div>
                  </div>
                </div>
                <div class="label label-success label-inline font-weight-bold py-4 px-3 font-size-base">
                  {{ walletReais.created_at }}
                </div>
              </div>
              <!--end::Item-->
            </div>

            <div class="card-footer d-flex justify-content-center bg-transparent align-items-center">
              <router-link v-slot="{ href, navigate }" custom to="/financial/real">
                <a
                  :href="href"
                  class="btn btn-icon btn-success btn-lg btn-shadow m-2 py-12 px-14 d-flex flex-column"
                  @click="navigate"
                >
                  <span class="svg-icon svg-icon-2x mb-2">
                    <inline-svg src="/media/svg/icons/Layout/Layout-left-panel-2.svg" />
                  </span>
                  <span class="nav-text font-weight-bolder">Extrato</span>
                </a>
              </router-link>

              <router-link v-slot="{ href, navigate }" custom to="/financial/real/transfer">
                <a
                  :href="href"
                  class="btn btn-icon btn-success btn-lg btn-shadow m-2 py-12 px-14 d-flex flex-column"
                  @click="navigate"
                >
                  <span class="svg-icon svg-icon-2x mb-2">
                    <inline-svg src="/media/svg/icons/Navigation/Up-down.svg" />
                  </span>
                  <span class="nav-text font-weight-bolder">Transferir</span>
                </a>
              </router-link>

              <a class="btn btn-icon btn-success btn-lg btn-shadow m-2 py-12 px-14 d-flex flex-column" @click="deposit">
                <span class="svg-icon svg-icon-2x mb-2">
                  <inline-svg src="/media/svg/icons/Shopping/Safe.svg" />
                </span>
                <span class="nav-text font-weight-bolder">Depositar</span>
              </a>
            </div>
            <!--end::Items-->
          </div>
        </div>
      </div>

      <!--end::Body-->
    </div>
  </OverlayLoader>

  <!--end::Tiles Widget 8-->
</template>

<script>
import { mapGetters } from 'vuex';
import QrcodeVue from 'qrcode.vue';
import OverlayLoader from '@/shared/content/OverlayLoader.vue';

export default {
  name: 'Widget4',
  components: {
    QrcodeVue,
    OverlayLoader
  },
  props: {
    cardStretch: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      activeItem: 'wallet_snail',
      walletSnail: {
        address: '',
        created_at: '--',
        email: '--'
      },
      walletReais: {
        address: '',
        created_at: '--',
        email: '--'
      }
    };
  },
  computed: {
    ...mapGetters(['currentWalletSnail', 'currentWalletReal', 'currentUser'])
  },
  mounted() {
    // console.log(this.currentWalletSnail);
    if (this.currentWalletSnail) {
      this.walletSnail.address = this.currentWalletSnail.address;
      this.walletSnail.created_at = this.formatDateGet(this.currentWalletSnail.created_at);
      this.walletSnail.email = this.currentUser.email;
    }
    if (this.currentWalletReal) {
      this.walletReais.address = this.currentWalletReal.address;
      this.walletReais.created_at = this.formatDateGet(this.currentWalletReal.created_at);
      this.walletReais.email = this.currentUser.email;
    }
  },
  methods: {
    formatDateGet(value) {
      return this.$moment(value).format('DD/MM/YYYY');
    },
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
    },
    async deposit() {
      await this.$router.push({ name: 'wallet-real-deposit' });
      this.$bvModal.show('modal-new-deposit');
    }
  }
};
</script>
